import { BaseServe } from '@/server'

// 领域分类
const fetchFieldClassify = (params) => {
  return BaseServe({
    url: '/api/disease_field/',
    params: {
      ...params
    }
  })
}

// 我的检索式
const fetchOwnerSearch = (params) => {
  return BaseServe({
    url: '/api/retrieval/',
    params: {
      ...params
    }
  })
}

// 高级检索式记录
const fetchAdvancedSearch = (params) => {
  return BaseServe({
    url: '/api/retrieval/',
    params: {
      ...params
    }
  })
}

// 订阅相关
const fetchFormulasSubscribe = (params) => {
  return BaseServe({
    url: '/api/retrieval/',
    method: 'POST',
    data: {
      ...params
    }
  })
}

// 订阅删除
const deleteFormulas = (id) => {
  return BaseServe({
    url: `/api/retrieval/${id}`,
    method: 'put',
    data: {
      is_subscribed: '0'
    }
  })
}

// 筛选项
const fetchFiltersItems = (params) => {
  return BaseServe({
    url: '/api/tags/',
    params: {
      ...params
    }
  })
}

// 列表
const fetchArticles = (params) => {
  return BaseServe({
    url: '/api/articles/',
    params: {
      ...params
    }
  })
}

// 列表导出
const exportArticles = (params) => {
  return BaseServe({
    url: '/api/articles/export',
    params: {
      ...params
    }
  })
}

// 收藏
const collectApi = (data) => {
  return BaseServe({
    url: '/users/collections/',
    method: 'post',
    data
  })
}

// 取消收藏
const deleteCollectApi = (pk, data) => {
  return BaseServe({
    url: `/users/collection/${pk}/`,
    method: 'put',
    data
  })
}

// 点击文献埋点
const clickLiteratureBase = (id) => {
  return BaseServe({
    url: `/api/article/${id}/`,
    loading: false
  })
}

export {
  fetchFieldClassify,
  fetchOwnerSearch,
  fetchAdvancedSearch,
  fetchFiltersItems,
  fetchArticles,
  fetchFormulasSubscribe,
  deleteFormulas,
  exportArticles,
  collectApi,
  deleteCollectApi,
  clickLiteratureBase
}