import { BaseServe } from '@/server'

// 个人信息接口
const fetchPersonalInfo = (userId) => {
  return BaseServe({
    url: `/users/account/${userId}`
  })
}

// 修改个人信息
const fetchPersonalBasicInfo = (params) => {
  return BaseServe({
    url: '/users/reset_basis/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 修改密码
const fetchPersonalPassword = (params) => {
  return BaseServe({
    url: '/users/reset_password/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 修改手机号
const fetchPersonalMobile = (params) => {
  return BaseServe({
    url: '/users/reset_mobile/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 反馈提交
const fetchPostFeedBack = (params) => {
  return BaseServe({
    url: '/users/feedback/',
    method: 'POST',
    data: {
      ...params
    }
  })
}

export {
  fetchPostFeedBack,
  fetchPersonalInfo,
  fetchPersonalBasicInfo,
  fetchPersonalPassword,
  fetchPersonalMobile
}