import { BaseServe } from '@/server'

// 访问趋势
const fetchVisitTrend = (params) => {
  return BaseServe({
    url: '/users/click/',
    params: {
      ...params
    }
  })
}

// 检索类型分布
const fetchSearchTypeDistribute = (params) => {
  return BaseServe({
    url: '/users/retrieve_subsection/',
    params: {
      ...params
    }
  })
}

// 生物标志物关注度
const fetchBioMarkerFocus = (params) => {
  return BaseServe({
    url: '/users/bio_marker/',
    params: {
      ...params
    }
  })
}

// 疾病领域关注度
const fetchDiseaseFieldFocus = (params) => {
  return BaseServe({
    url: '/users/field/',
    params: {
      ...params
    }
  })
}

// 指标点击详情
const fetchLabelsClickDetail = (params) => {
  return BaseServe({
    url: '/users/index/',
    params: {
      ...params
    }
  })
}

// 疾病领域
const fetchFieldsInfo = (params) => {
  return BaseServe({
    url: '/api/disease_field_ids/',
    params: {
      ...params
    },
    loading: false
  })
}

// 热门图表
const fetchHotCharts = (params) => {
  return BaseServe({
    url: '/users/figures/',
    params: {
      ...params
    }
  })
}

// 点击详情
const fetchChartClickDetail = (params) => {
  return BaseServe({
    url: '/users/figure/',
    params: {
      ...params
    }
  })
}

// Top热门搜索词
const fetchTopHotSearch = (params) => {
  return BaseServe({
    url: '/users/keywords/',
    params: {
      ...params
    }
  })
}

// 高级检索热门筛选
const fetchTopHotSearchFilter = (params) => {
  return BaseServe({
    url: '/users/advanced_key/',
    params: {
      ...params
    }
  })
}

// 文献点击收藏
const fetchLiteratureRank = (params) => {
  return BaseServe({
    url: '/users/review/',
    params: {
      ...params
    }
  })
}

export {
  fetchVisitTrend,
  fetchSearchTypeDistribute,
  fetchBioMarkerFocus,
  fetchDiseaseFieldFocus,
  fetchLabelsClickDetail,
  fetchFieldsInfo,
  fetchHotCharts,
  fetchChartClickDetail,
  fetchTopHotSearch,
  fetchTopHotSearchFilter,
  fetchLiteratureRank
}