import { BaseServe } from '@/server'

// 登陆相关
const fetchLogin = (params) => {
  return BaseServe({
    url: '/users/login/',
    method: 'POST',
    data: {
      ...params
    }
  })
}

// 获取验证码
const fetchVerifyCode = (params) => {
  return BaseServe({
    url: '/users/captcha/',
    params: {
      ...params
    }
  })
}

// 验证码登陆
/**
 *
 * @params: { mobile}
 * @params: { captcha}
 * @params: { is_login}
 */
const loginVerifyCode = (params) => {
  return BaseServe({
    url: '/users/login_by_code/',
    method: 'POST',
    data: {
      ...params
    }
  })
}

// 用户直接注册
const fetchDirectRegister = (params) => {
  return BaseServe({
    url: '/users/register/',
    method: 'POST',
    data: {
      ...params
    }
  })
}

// 用户手机号验证码跳转注册
const fetchNotDirectRegister = (params) => {
  return BaseServe({
    url: '/users/register/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 重置密码
const fetchResetPassword = (params) => {
  return BaseServe({
    url: '/users/forget_password/',
    method: 'PUT',
    data: {
      ...params
    }
  })
}

// 匹配单位医院
const fetchInstitute = (params, loading) => {
  return BaseServe({
    url: '/users/institute/',
    params: {
      ...params
    },
    loading
  })
}

export {
  fetchLogin,
  fetchVerifyCode,
  fetchDirectRegister,
  loginVerifyCode,
  fetchInstitute,
  fetchNotDirectRegister,
  fetchResetPassword
}