import { BaseServe } from '@/server'

// 发表趋势
const fetchPublishTrend = (params) => {
  return BaseServe({
    url: '/api/dashboard/publish_year/',
    params: {
      ...params
    }
  })
}

// 文献类型
const fetchLiteratureType = (params) => {
  return BaseServe({
    url: '/api/dashboard/article_type/',
    params: {
      ...params
    }
  })
}

// 影响因子分布
const fetchImpactFactory = (params) => {
  return BaseServe({
    url: '/api/dashboard/impact_factor/',
    params: {
      ...params
    }
  })
}

// 作者排行
const fetchAuthorsRank = (params) => {
  return BaseServe({
    url: '/api/dashboard/author/',
    params: {
      ...params
    }
  })
}

// 机构排行
const fetchInstitutionRank = (params) => {
  return BaseServe({
    url: '/api/dashboard/institute/',
    params: {
      ...params
    }
  })
}

// 相关疾病
const fetchRelativeDisease = (params) => {
  return BaseServe({
    url: '/api/dashboard/disease/',
    params: {
      ...params
    }
  })
}

// 临床应用
const fetchClinicalApplication = (params) => {
  return BaseServe({
    url: '/api/dashboard/clinical_application/',
    params: {
      ...params
    }
  })
}

// 研究人群
const fetchResearchCrowd = (params) => {
  return BaseServe({
    url: '/api/dashboard/population/',
    params: {
      ...params
    }
  })
}

// 中英文文献
const fetchLangLiterature = (params) => {
  return BaseServe({
    url: '/api/dashboard/language/',
    params: {
      ...params
    }
  })
}

// 高频关键词
const fetchFrequencyWords = (params) => {
  return BaseServe({
    url: '/api/dashboard/keywords/',
    params: {
      ...params
    }
  })
}

export {
  fetchPublishTrend,
  fetchLiteratureType,
  fetchImpactFactory,
  fetchAuthorsRank,
  fetchInstitutionRank,
  fetchRelativeDisease,
  fetchClinicalApplication,
  fetchResearchCrowd,
  fetchLangLiterature,
  fetchFrequencyWords
}