<template>
  <div class="lists-card-wrap-items">
    <div class="lists-card-header">
      <div class="left">
        <Checkbox
          :indeterminate="unsure"
          :value="checkAll"
          @click.prevent.native="handleCheckAll">
          <span class="all">全选</span>
        </Checkbox>
        <div class="info">
          共<span>{{count}}</span>条数据，已选<span>{{isSelect}}</span>条
        </div>
      </div>
      <div class="right">
        <div class="sort">
          <div class="time" :class="{'sort-lighten': relateLighten}" @click="relateSort" v-if="!library">
            相关度
          </div>
          <div class="time" :class="{'sort-lighten': collectLighten}" @click="collectTimeSort" v-if="library">
            收藏时间
            <i class="iconfont iconbianzu22" :class="{'sort-lighten': collectAsc}"></i>
            <i class="iconfont iconbianzu21 icon_right" :class="{'sort-lighten': collectDesc}"></i>
          </div>
          <div class="time" :class="{'sort-lighten': timeLighten}" @click="timeSort" >
            发表时间
            <i class="iconfont iconbianzu22" :class="{'sort-lighten': timeAsc}"></i>
            <i class="iconfont iconbianzu21 icon_right" :class="{'sort-lighten': timeDesc}"></i>
          </div>
          <div class="influence" :class="{'sort-lighten': iFLighten}" @click="impactSort">
            IF
            <i class="iconfont iconbianzu22" :class="{'sort-lighten': ifAsc}"></i>
            <i class="iconfont iconbianzu21 icon_right" :class="{'sort-lighten': ifDesc}"></i>
          </div>
        </div>
        <div  class="divide">
          <Divider type="vertical"/>
        </div>
        <div class="icons" :class="{'library': library}">
          <!-- <Tooltip content="收藏" v-if="!library">
            <span class="iconfont iconshoucang1" @click="collect"></span>
          </Tooltip> -->
          <Tooltip content="导出">
            <span class="iconfont iconxiazai1"
            @click="download"></span>
          </Tooltip>
        </div>
        <div class="dash-new" v-if="!isDetail && !library">
          <Button type="primary" ghost @click="handleDashboard">数据看板</Button>
          <Button class="new" type="primary" ghost @click="handleNew">NEW {{ newCount || ''}}</Button>
          <Button class="new" type="primary" :ghost="!fullTextVisible" @click="handleFullText">Full text</Button>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { Checkbox, Divider, Button, Tooltip } from 'view-design'
export default {
  name: 'ListCard',

  props: {
    count: {
      type: Number,
      default: 100
    },
    isSelect: {
      type: Number,
      default: 0
    },
    unsure: {
      type: Boolean,
      default: true
    },
    checkAll: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    timeLighten: {
      type: Boolean,
      default: false
    },
    collectLighten: {
      type: Boolean,
      default: false
    },
    timeAsc: {
      type: Boolean,
      default: false
    },
    timeDesc: {
      type: Boolean,
      default: false
    },
    collectAsc: {
      type: Boolean,
      default: false
    },
    collectDesc: {
      type: Boolean,
      default: false
    },
    ifAsc: {
      type: Boolean,
      default: false
    },
    ifDesc: {
      type: Boolean,
      default: false
    },
    relateLighten: {
      type: Boolean,
      default: false
    },
    iFLighten: {
      type: Boolean,
      default: false
    },
    newCount: {
      type: Number,
      default: 0
    },
    library: {
      type: Boolean,
      default: false
    },
    fullTextVisible: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Checkbox,
    Divider,
    Button,
    Tooltip
  },

  watch: {
    checkAll: {
      handler(val) {
        console.log(val)
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    handleCheckAll() {
      this.$emit('all')
    },
    collect() {
      this.$emit('collect')
    },
    download() {
      this.$emit('download')
    },
    timeSort() {
      this.$emit('time', this.timeLighten)
    },
    impactSort() {
      this.$emit('impact', this.iFLighten)
    },
    relateSort() {
      this.$emit('relate', this.relateLighten)
    },
    collectTimeSort() {
      this.$emit('collect', this.collectLighten)
    },
    handleDashboard() {
      this.$emit('dashboard')
    },
    handleNew() {
      this.$emit('new')
    },
    handleFullText() {
      this.$emit('fullText')
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>