<template>
  <div class="dashboard-detail-wrap">
    <div class="header-info">
      <div class="detail-left">
        <div class="home">
          <span class="home-index" @click="isGoBack">
            <i class="iconfont iconxinxiku"></i>
            文献库首页
          </span>
          <!-- / <span @click="isBackOne">{{field}} <span v-if="category">/ {{category}}</span></span> -->
          / <span class="last-label">{{chartName}}</span>
        </div>
        <div class="header-formulas" v-if="formulas.length">
          <div class="formula-item"
            v-for="(item, index) in formulas"
            :key="item+index"
          >
          <span v-if="index !== 0 && index !== 1">and</span>
          {{item}}
          <span v-if="index === 0 && formulas.length > 1">-</span>
          </div>
        </div>
      </div>
      <div class="detail-right">
        <Button type="primary" ghost @click="isBack">返回</Button>
      </div>
    </div>
    <div class="dashboard-detail-content">
      <ListCard
        :count="total"
        :checkAll="checkAll"
        :unsure="unsure"
        :isDetail="true"
        :relateLighten="sortRelate"
        :timeLighten="sortTime"
        :timeAsc="timeAsc"
        :timeDesc="timeDesc"
        :ifAsc="ifAsc"
        :ifDesc="ifDesc"
        :iFLighten="sortIF"
        :isSelect="checkAllGroup.length"
        @collect="isCollect"
        @download="isDownload"
        @time="isTimeSort"
        @impact="isImpactSort"
        @relate="isRelateSort"
        @all="isCheckAll"
      >
        <CheckboxGroup
          v-model="checkAllGroup"
          @on-change="checkAllGroupChange"
        >
          <ListItem
            v-for="(item, index) in lists"
            :key="index"
            :option="item"
            :itemKey="index"
            @fold="isFold"
            @collect="isCardCollect"
            @more="toggleMore"
          />
        </CheckboxGroup>
        <div class="literature-lists-footer">
          <Page
            :total="total"
            show-elevator
            @on-change="isPageChange"
          />
        </div>
      </ListCard>
    </div>
  </div>
</template>

<script>
import {
  fetchArticles
} from '@/api'
import ListCard from '../components/ListCard'
import ListItem from '../components/ListItem'
import { CheckboxGroup, Page, Message, Button } from 'view-design'
import {
  savePdfWithOpen
} from '@/utils/tools'
export default {
  name: 'Detail',
  data () {
    return {
      field: '',
      category: '',
      chartName: '',
      unsure: false,
      sortRelate: true,
      checkAll: false,
      sortTime: false,
      timeAsc: false,
      timeDesc: false,
      sortIF: false,
      ifAsc: false,
      ifDesc: false,
      checkAllGroup: [],
      lists: [],
      total: 0,
      options: {},
      page: 1,
      formulas: []
    }
  },

  created() {
    const { query } = this.$route
    const combine = JSON.parse(query.combine)
    const { page, field, variety, chartName, disease__id, bio_markers, bio_expression, bio_markers__bio_marker_id__in, clinical_application__id__in, disease__id__in, language__in, population__id__in, publish_year__in, is_new, article_type__id__in, impact_factor__range, field__id, article__authors__contains, formulas, disease__name, clinical_application__name, population__name, article_type__name, institute__contains } = combine
    this.field = field
    this.category = variety
    this.chartName = chartName
    this.page = page
    this.formulas = formulas
    this.options = {
      page: this.page,
      disease__id: disease__id,
      field__id,
      bio_markers,
      bio_expression,
      bio_markers__bio_marker_id__in,
      disease__id__in,
      clinical_application__id__in,
      population__id__in,
      language__in,
      article_type__id__in,
      publish_year__in,
      impact_factor__range,
      article__authors__contains,
      disease__name,
      clinical_application__name,
      population__name,
      institute__contains,
      // publish_year_asc: this.sortTime ? '0' : undefined,
      // impact_factor_asc: this.sortIF ? '0' : undefined,
      is_new,
      article_type__name
    }
    this.getArticles()
  },

  components: {
    ListCard,
    ListItem,
    CheckboxGroup,
    Page,
    Button
  },

  computed: {
    sortOptions({ sortRelate, sortTime, sortIF, timeDesc, ifDesc }) {
      if (sortRelate) {
        return {
          relevance_asc: '0'
        }
      } else if (sortTime) {
        return {
          publish_year_asc: timeDesc ? '0' : '1'
        }
      } else if (sortIF) {
        return {
          impact_factor_asc: ifDesc ? '0' : '1'
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    async getArticles() {
      this.unsure = false
      this.checkAll = false
      this.checkAllGroup = []
      const res = await fetchArticles({
        ...this.options,
        ...this.sortOptions
      })
      const { data: { data }, page: { count } } = res.data
      this.total = count
      this.lists = data.map(item => ({
        unfold: false,
        collect: false,
        isMore: false,
        ...item
      }))
    },
    isBack() {
      this.$router.go(-1)
    },
    isGoBack() {
      this.$router.go(-2)
    },
    isBackOne() {
      this.$router.go(-1)
    },
    isCollect() {
      console.log('collect')
    },
    exportArticles() {
      const temp = {
        id__in: this.checkAllGroup.join(',')
      }
      savePdfWithOpen(this.options, 'articles/export', temp)
    },
    isDownload() {
      if (this.checkAllGroup.length) {
        this.exportArticles()
        this.checkAllGroup = []
        this.checkAll = false
      } else {
        Message.warning({
          content: '请选择要导出的数据！',
          duration: 2
        })
      }
    },
    isRelateSort() {
      if (!this.sortRelate) {
        this.sortRelate = true
        this.sortIF = this.sortTime = this.timeDesc = this.timeAsc = this.ifDesc = this.ifAsc = false
        this.page = 1
        this.getArticles()
      }
    },
    isTimeSort() {
      this.sortTime = true
      if (!this.timeDesc) {
        this.timeAsc = false
        this.timeDesc = true
      } else {
        this.timeAsc = true
        this.timeDesc = false
      }
      this.sortIF = this.sortRelate = this.ifDesc = this.ifAsc = false
      this.page = 1
      this.getArticles()
    },
    isImpactSort() {
      this.sortIF = true
      if (!this.ifDesc) {
        this.ifAsc = false
        this.ifDesc = true
      } else {
        this.ifAsc = true
        this.ifDesc = false
      }
      this.sortTime = this.sortRelate = this.timeDesc = this.timeAsc = false
      // this.options.impact_factor_asc = this.sortIF ? '0' : undefined
      this.page = 1
      this.getArticles()
    },
    isCheckAll() {
      if (this.unsure) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.unsure = false
      if (this.checkAll) {
        const temp = []
        this.lists.forEach(item => temp.push(item.id))
        this.checkAllGroup = temp
      } else {
        this.checkAllGroup = []
      }
    },
    checkAllGroupChange(data) {
      if (data.length === this.lists.length) {
        this.unsure = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.unsure = true
        this.checkAll = false
      } else {
        this.unsure = false
        this.checkAll = false
      }
    },
    isFold(key) {
      this.lists[key].unfold = !this.lists[key].unfold
    },
    isCardCollect(key) {
      this.lists[key].collect = !this.lists[key].collect
    },
    isPageChange(page) {
      this.checkAllGroup = []
      this.checkAll = false
      this.unsure = false
      this.page = page
      this.options.page = this.page
      this.getArticles()
    },
    toggleMore(key) {
      this.lists[key].isMore = !this.lists[key].isMore
    }
  }
}

</script>
<style lang='less' scoped>
@import url('./index.less');
</style>