<template>
  <div class="list-item-wrap">
    <div class="list-item-header">
      <div class="left">
        <Checkbox :label="option.id">
          <span></span>
        </Checkbox>
        <span @click="handleTitleClick" class="title" v-html="option.title"></span>
        <div class="is-new" v-if="option.is_new">
          <img src="../../../../assets/imgs/new.png">
        </div>
      </div>
      <div class="right" @click="handleCollect">
        <i class="iconfont iconshoucang1" :class="{'active': isCollect}"></i>
      </div>
    </div>
    <div class="label-common author" v-if="isArtice">
      作者：
      <span class="is-more span_val" ref="authors">
        <span :class="{'ellipsis': isMore && !option.isMore}">{{setString(option.other_info.authors) || '-'}}</span>
        <span class="more" @click="isToggleMore" v-if="isMore">
        More
        </span>
      </span>
    </div>
    <div class="label-common author" v-if="!isArtice">
      发布机构：<span class="span_val" style="margin-left: 4px;">{{option.institute || '-'}}</span>
    </div>
    <div class="pmid-year">
      <div class="label-common year">
        发表年份：<span class="span_val">{{option.publish_year || '-'}}</span>
      </div>
      <div class="label-common pmid" v-if="isArtice && option.other_info.pmid">
        PMID：<span class="span_val">{{option.other_info.pmid || '-'}}</span>
      </div>
    </div>
    <div class="label-common keywords" v-if="option.other_info.keywords && option.other_info.keywords.length">
      <!-- 关键词：<span>{{setString(option.other_info.keywords) || '-'}}</span> -->
      关键词：<span style="margin-left: 18px;color: #007FFF" class="span_val" v-html="setString(option.other_info.keywords) || '-'"></span>
    </div>
    <div class="article-info">
      <div class="label-common left">
        来源链接：<template v-if="option.source_url">
          <!-- <a :href="option.source_url" target="blank" @click="handleIink">{{option.source_url.length > 72 ? `${option.source_url.slice(0, 72)}...` : option.source_url}}</a> -->
          <!-- <a :href="option.source_url" target="blank">{{option.source_url}}</a> -->
          <a :href="option.source_url" target="blank">
            <span class="pubmed" v-if="option.source === 'pubmed'">Pubmed</span>
            <span class="zhiwang" v-if="option.source === '知网'">中国知网</span>
            <span class="wanfang" v-if="option.source === '万方医学'">万方医学</span>
            <span class="zhyxqk" v-if="option.source === '中华医学期刊网'">中华医学期刊网</span>
          </a>
        </template>
        <template v-else>
          <a style="color: #494E53">-</a>
        </template>
      </div>

    </div>
    <div class="article-info journal">
      <div class="right" v-if="option.journal || option.impact_factor">
        <span class="journal" v-if="option.journal">{{option.journal.length > 64 ? `${option.journal.slice(0, 32)}...` : option.journal}} <span style="color: #007FFF">{{option.journal_type_info}}</span></span>
        <span class="factor" v-if="isArtice && option.impact_factor">impact factor:{{option.impact_factor.toFixed(2)}}</span>
      </div>
    </div>
    <div class="article-info journal collect" v-if="option.created_time">
      收藏时间： {{option.created_time}}
    </div>
    <!-- <div class="abstract">
      <div class="exec" :class="{'active': option.unfold}">
        <div class="exec-words" @click="handleFold" :class="{'word-active': option.unfold}">{{ language === 'eng' ? isArtice ? 'Abstract' : 'Summary' : '摘要'}}</div>
        <div class="iconfont iconxiala" :class="{'fold': option.unfold}"></div>
      </div>
      <div class="abstract-content" v-if="option.unfold">
        <span v-html="isArtice ? option.abstract : options.summery"></span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Checkbox, Message } from 'view-design'
import { collectApi, deleteCollectApi, clickLiteratureBase } from '@/api/'
export default {
  name: 'ListItem',

  data() {
    return {
      isMore: false,
      isCollect: this.option.is_collection.is_collection,
      deleteId: this.option.is_collection.pk
    }
  },

  props: {
    option: {
      type: Object,
      default: () => {}
    },
    itemKey: {
      type: Number,
      default: 0
    },
    isLibrary: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Checkbox
  },

  watch: {
    option: {
      handler(val) {
        console.log(val)
        if (val) {
          this.isCollect = val.is_collection.is_collection
          this.deleteId = val.is_collection.pk
          this.$nextTick(() => {
            if (this.isArtice) {
              if (this.$refs.authors.getBoundingClientRect().height > 21) {
                this.isMore = true
              } else {
                this.isMore = false
              }
            }
          })
        }
      },
      immediate: true
    }
  },

  computed: {
    isArtice() {
      const type = this.option.article_data_type
      return type === 'article'
    },
    language() {
      return this.option.language
    },
    setString() {
      return function (val) {
        return Array.isArray(val) && val.join(',')
      }
    }
  },

  methods: {
    handleTitleClick() {
      if (this.option.source_url) {
        window.open(this.option.source_url)
      }
    },
    handleIink() {
      const { id } = this.option
      clickLiteratureBase(id)
    },
    handleFold() {
      const { id, unfold } = this.option
      if (!unfold && !this.isLibrary) {
        clickLiteratureBase(id)
      }
      this.$emit('fold', this.itemKey)
    },
    handleCollect() {
      if (this.isCollect) {
        deleteCollectApi(this.deleteId, {
          is_collected: false
        }).then(res => {
          const { code } = res
          if (code === 200) {
            this.isCollect = false
            Message.success('取消收藏')
            this.$emit('collect', this.itemKey)
          }
        })
      } else {
        collectApi({
          article_id: this.option.id
        }).then(res => {
          const { data } = res.data
          this.isCollect = true
          this.deleteId = data.id
          Message.success('收藏成功')
        })
      }
    },
    isToggleMore() {
      this.$emit('more', this.itemKey)
    }
  }
}

</script>
<style lang='less'>
@import url('./index.less');
</style>