import { BaseServe } from '@/server'

// 获取收藏的文献
const fetchCollectLiterature = (params) => {
  return BaseServe({
    url: '/users/collections/',
    params: {
      ...params
    }
  })
}

// 获取收藏的tags
const fetchCollectionsTags = (params) => {
  return BaseServe({
    url: '/users/collection_tags/',
    params: {
      ...params
    }
  })
}

export {
  fetchCollectLiterature,
  fetchCollectionsTags
}